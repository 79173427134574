import util from '@/utils/common'

const tagObj = {
    label: '', //标题名称
    value: '', //标题的路径
    params: '', //标题的路径参数
    query: '', //标题的参数
    meta: {} //额外参数
}

const tags = {
    state: {
        tagList: util.GetStore('tagList') || [],
        tag: util.GetStore('tag') || tagObj
    },
    actions: {},
    mutations: {
        ADD_TAG: (state, action) => {
            state.tag = action
            util.SetStore('tag', state.tag)
            if (state.tagList.some((ele) => util.ObjDiff(ele, action))) return
            state.tagList.push(action)
            util.SetStore('tagList', state.tagList)
        },
        DEL_TAG: (state, action) => {
            state.tagList = state.tagList.filter((item) => {
                return !util.ObjDiff(item, action)
            })
            util.SetStore('tagList', state.tagList)
        },
        DEL_ALL_TAG: (state) => {
            state.tagList = []
            util.SetStore('tagList', state.tagList)
        },
        DEL_TAG_OTHER: (state) => {
            state.tagList = state.tagList.filter((item) => {
                if (item.value === state.tag.value) {
                    return true
                }
            })
            util.SetStore('tagList', state.tagList)
        }
    }
}
export default tags
