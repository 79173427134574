const getters = {
    SID: (state) => state.user.SID,
    USER: (state) => state.user.USER,
	Token: (state) => state.user.Token,
	RefreshToken: (state) => state.user.RefreshToken,
	UserInfo: (state) => state.user.UserInfo,
    Menu: (state) => state.user.Menu,
    MenuAll: (state) => state.user.MenuAll,
    colorName: (state) => state.common.colorName,
    themeName: (state) => state.common.themeName,
    collapsed: (state) => state.common.collapsed,
    tag: (state) => state.tags.tag,
    tagList: (state) => state.tags.tagList
}
export default getters
