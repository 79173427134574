<template>
    <div class="avue-sidebar">
        <logo></logo>
        <el-scrollbar style="height:100%">
            <div v-if="$Util.IsNull(Menu)" class="avue-sidebar--tip">无菜单</div>
            <el-menu :default-active="$route.path" mode="vertical" :show-timeout="200" :collapse="collapsed">
                <sidebar-item :menu="Menu" first :collapse="collapsed"></sidebar-item>
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logo from '../logo'
import sidebarItem from './item'
export default {
    name: 'sidebar',
    components: {
        sidebarItem,
        logo
    },
    inject: ['index'],
    computed: {
        ...mapGetters(['Menu', 'collapsed'])
    }
}
</script>
<style lang="scss" scoped></style>
