import axios from 'axios'
import util from '@/utils/common'
import config from '@/api/config'
import store from '@/store'

// create an axios instance
const service = axios.create({
	baseURL: process.env.BASE_API, // api的base_url
	timeout: 150000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	(config) => {
		config.headers = {
			...config.headers
		}

		//调用需要Token认证的接口，请求前设置Header的Authorizatioin
		if(config.headers.auth !== false) {
			let token = store.getters.Token
			let refresh_token = store.getters.RefreshToken
			if(!token && !refresh_token) {
				util.NavigateTo('/login')
				return Promise.reject('UnAuthorized')
			}
			config.headers.Authorization = "Bearer " + token
		}

		return config
	},
	(error) => {
		console.log(error) // for debug
		util.Notice(error, -1)
		return Promise.reject(error)
	}
)

// respone interceptor
service.interceptors.response.use(
	async (response) => {
		if(response.config.headers.auth !== false) {
			if(response.data.Message == "UnAuthorized") {
				// 刷新Token
				let refresh_token = store.getters.RefreshToken
				let param = { RefreshToken: refresh_token, ClientID: config.api.clientID }
				let url = config.api.serviceUrl + 'AdminUser/RefreshToken/Authorize'
				service({
					url: url,
					method: 'post',
					data: param,
				}).then(async (resp) => {
					if(resp.status !== 200) {
						store.commit('SET_TOKEN', '')
						store.commit('SET_REFRESHTOKEN', '')
						util.NavigateTo('/login')
						return response
					}else{
						if (resp.data.Status === 0) {
							store.commit('SET_TOKEN', '')
							store.commit('SET_REFRESHTOKEN', '')
							util.NavigateTo('/login')
							return response
						}
						else {
							store.commit('SET_TOKEN', resp.data.Data.Token)
							store.commit('SET_REFRESHTOKEN', resp.data.Data.RefreshToken)
						}
					}
					window.location.reload()
					// util.MsgBox('长时间未操作会话已过期，自动重连成功，请重新发起操作请求！')
					return response
				})
					.catch((errMsg) => {
						util.Notice(errMsg)
					})
			}
		}
		return response
	},
	(error) => {
		console.log('err' + error) // for debug
		// util.Notice('连接服务器失败！', -1)
		return Promise.reject(error)
	}
)

export default service
