<template>
    <div class="app-container" :style="styleName" :class="{ 'app-container--block': block }">
        <el-card>
            <slot></slot>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'appContainer',
    props: {
        radius: {
            type: [String, Number],
            default: 10
        },
        background: {
            type: String
        },
        block: {
            type: Boolean,
            default: true
        },
        diffHeight: {
            type: Number,
            default: 100
        }
    },
    computed: {
        styleName() {
            return {
                borderRadius: this.setPx(this.radius),
                background: this.background,
                height: this.winHeight - this.diffHeight + 'px'
            }
        }
    },
    mounted() {
        //获取窗体的高度
        this.winHeight = window.innerHeight
        let that = this
        window.onresize = () => {
            return (() => {
                that.winHeight = window.innerHeight
            })()
        }
    }
}
</script>

<style lang="scss">
.app-container {
    padding: 5px 5px;
    box-sizing: border-box;
    &--block {
        height: 100%;
        .el-card {
            height: 100%;
        }
    }
    .el-card {
        width: 100%;
        &__body{
            padding: 5px;
        }
    }
    &:first-child {
        padding-top: 0;
    }
}
</style>
