<template>
    <div class="avue-contail" :class="{ 'avue--collapse': collapsed }">
        <div class="avue-header">
            <!-- 顶部导航栏 -->
            <navbar ref="top" />
        </div>

        <div class="avue-layout">
            <div class="avue-left">
                <!-- 左侧导航栏 -->
                <sidebar />
            </div>
            <div class="avue-main">
                <!--:class="{'avue-main&#45;&#45;fullscreen':!isMenu}">-->
                <!-- 顶部标签卡 -->
                <tags />
                <!-- 主体视图层 -->
                <div style="height:100%;overflow-y:auto;overflow-x:hidden;" id="avue-view">
                    <!--v-show="!isSearch">-->
                    <keep-alive>
                        <router-view class="avue-view" :key="$route.fullPath" v-if="$route.meta.keepAlive" />
                    </keep-alive>
                    <router-view class="avue-view" :key="$route.fullPath" v-if="!$route.meta.keepAlive" />
                </div>
            </div>
        </div>
        <!-- <el-footer class="avue-footer">
          <img src="/svg/logo.svg"
               alt=""
               class="logo">
          <p class="copyright">© 2018 Avue designed by smallwei</p>
        </el-footer> -->
        <div class="avue-shade" @click="showCollapse"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navbar from './navbar/navbar'
import sidebar from './sidebar/sidebar'
import tags from './tags'

export default {
    components: {
        navbar,
        sidebar,
        tags
    },
    name: 'home',
    provide() {
        return {
            index: this
        }
    },
    data() {
        return {}
    },
    computed: mapGetters(['Menu', 'collapsed']),
    props: [],
    mounted() {
        this.$store.commit('SET_THEME_NAME', 'theme-white')
        this.$store.commit('SET_COLOR_NAME', '#f86d85')
    },
    methods: {
        showCollapse() {
            this.$store.commit('SET_COLLAPSE')
        },
        //打开菜单
        openMenu(item = {}) {
            if (this.$Util.IsNull(item)) return
            if (this.$Util.IsNull(item.ID)) return

            if(this.$Menu.menu === 'local'){
                // let sideMenu = this.$Menu.sideMenu.filter((subItem) => {
                //     return subItem.ParentID === item.ID
                // })

                // this.$router.$dynamicRouter.formatRoutes(sideMenu, true)
                // this.$store.commit('SET_MENU', sideMenu)
                // if (this.$Menu.sideMenu.length > 0 && !this.$Util.IsNull(item['Path']) && this.$Util.IsNull(item['TagClick'])) {
                //     this.$Util.NavigateTo(item['Path'])
                // }
            }else{
                // this.$AdminApi.PostMethod('Menu', 'GetSubMenu', { MenuID: item.ID }, (resp) => {
                //     let sideMenu = resp.Data
                //     this.$router.$dynamicRouter.formatRoutes(sideMenu, 0)
                //     this.$store.commit('SET_MENU', sideMenu)
                //     if (resp.Data.length > 0 && !this.$Util.IsNull(item['Path']) && this.$Util.IsNull(item['TagClick'])) {
                //         this.$Util.NavigateTo(item['Path'])
                //     }
                // })
            }
        }
    }
}
</script>
