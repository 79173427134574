<template>
    <div class="bsy-bottom">
        <div style="padding-top: 700px;margin-top: 30px">知之信用管理（广东）有限公司 © 2022-2023</div>
        <el-link @click="link">备案号：粤ICP备2022075214号-1</el-link>
    </div>
</template>

<script>
export default {
    name: 'bsy-bottom',
    data() {
        return {
            config: this.$Config.login
        }
    },
    mounted() {

    },
	methods: {
        link() {
            window.open('https://beian.miit.gov.cn/')
        }
	}
}
</script>
<style lang="scss" scoped>
.bsy-bottom{
    background: url('https://www.zhizhixy.com/files/zhizhi/image/index/index_bottom.png');
    width: 100%;
    height: 100%;                   
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: contain;
    text-align: center;
    color: #606266 ;
    margin-top: -700px;
    z-index: 10;
}
</style>
