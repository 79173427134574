import util from '@/utils/common'

const user = {
    state: {
        SID: util.GetStore('SID') || '',
        USER: util.GetStore('USER') || {},
		Token: util.GetStore('Token') || '',
		RefreshToken: util.GetStore('RefreshToken') || '',
		UserInfo: util.GetStore('UserInfo') || {},
        Menu: util.GetStore('Menu') || [],
        MenuAll: util.GetStore('MenuAll') || []
    },
    mutations: {
        SET_SID: (state, SID) => {
            state.SID = SID
            util.SetStore('SID', state.SID)
        },
        SET_USER: (state, USER) => {
            state.USER = USER
            util.SetStore('USER', state.USER)
        },
		SET_TOKEN: (state, Token) => {
		    state.Token = Token
		    util.SetStore('Token', state.Token)
		},
		SET_REFRESHTOKEN: (state, RefreshToken) => {
		    state.RefreshToken = RefreshToken
		    util.SetStore('RefreshToken', state.RefreshToken)
		},
		SET_USERINFO: (state, UserInfo) => {
		    state.UserInfo = UserInfo
		    util.SetStore('UserInfo', state.UserInfo)
		},
        SET_MENU(state, Menu) {
            state.Menu = Menu
            util.SetStore('Menu', state.Menu)
        },
        SET_MENUALL(state, MenuAll) {
            state.MenuAll = MenuAll
            util.SetStore('MenuAll', state.MenuAll)
        }
    }
}
export default user
