import Layout from '@/page/index/'
import iframe from '@/page/index/home/'
export default [
    // {
    //     path: '/',
    //     redirect: '/main'
    // },
    // {
    //     path: '/',
    //     component: () => import(/* webpackChunkName: "page" */ '@/views/main/index'),
    //     name: '首页',
    //     meta: {
    //         keepAlive: true,
    //         isTab: false,
    //         isAuth: false
    //     }
    // },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                name: 'index',
                // component: () => import(/* webpackChunkName: "views" */ '@/views/main/index'),
                component: () => import(/* webpackChunkName: "views" */ '@/views/main/home'),
                meta: {
                    keepAlive: true,
                    title: '知之信用'
                }
            }
        ]
    },
    {
        path: '/news',
        component: Layout,
        children: [
            {
                path: '/news',
                name: 'news',
                component: () => import(/* webpackChunkName: "views" */ '@/views/main/news'),
                meta: {
                    keepAlive: false,
                    title: '新闻中心'
                }
            }
        ]
    },
    {
        path: '/about',
        component: Layout,
        children: [
            {
                path: '/about',
                name: 'about',
                component: () => import(/* webpackChunkName: "views" */ '@/views/main/about'),
                meta: {
                    keepAlive: true,
                    title: '关于我们'
                }
            }
        ]
    },
    {
        path: '/scope',
        component: Layout,
        children: [
            {
                path: '/scope-list',
                name: 'scope-list',
                component: () => import(/* webpackChunkName: "views" */ '@/views/main/scope-list'),
                meta: {
                    keepAlive: true,
                    title: '服务范围列表'
                }
            }
        ]
    },
    {
        path: '/policy',
        component: Layout,
        children: [
            {
                path: '/policy',
                name: 'policy',
                component: () => import(/* webpackChunkName: "views" */ '@/views/main/policy'),
                meta: {
                    keepAlive: false,
                    title: '政策法规'
                }
            }
        ]
    },
    {
        path: '/policy-list',
        component: Layout,
        children: [
            {
                path: '/policy-list',
                name: 'policy-list',
                component: () => import(/* webpackChunkName: "views" */ '@/views/main/policy-list'),
                meta: {
                    keepAlive: false,
                    title: '政策法规'
                }
            }
        ]
    },
    {
        path: '/download',
        component: Layout,
        children: [
            {
                path: '/download',
                name: 'download',
                component: () => import(/* webpackChunkName: "views" */ '@/views/main/download'),
                meta: {
                    keepAlive: false,
                    title: '下载中心'
                }
            }
        ]
    },

    {
        path: '/register',
        component: () => import(/* webpackChunkName: "views" */ '@/page/login/register'),
        name: 'register',
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "page" */ '@/page/login/index'),
        meta: {
            keepAlive: true,
            isTab: false
        }
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "page" */ '@/page/login/admin'),
        meta: {
            keepAlive: true,
            isTab: false
        }
    },
    // {
    //     path: '/home',
    //     component: BSYFrame,
    //     children: [
    //         {
    //             path: 'home',
    //             name: 'home',
    //             component: () => import(/* webpackChunkName: "views" */ '@/views/main/home'),
    //             meta: {
    //                 title: '首页'
    //             }
    //         }
    //     ]
    // },
    {
        path: '/403',
        component: () => import(/* webpackChunkName: "page" */ '@/components/error-page/403'),
        name: '403',
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "page" */ '@/components/error-page/404'),
        name: '404',
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '/500',
        component: () => import(/* webpackChunkName: "page" */ '@/components/error-page/500'),
        name: '500',
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '/iframe',
        component: iframe,
        redirect: '/iframe',
        children: [
            {
                path: ':routerPath',
                name: 'iframe',
                component: () => import(/* webpackChunkName: "page" */ '@/components/iframe/main'),
                props: true
            }
        ]
    },
    {
        path: '*',
        redirect: '/login'
    }
]
