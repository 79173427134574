/**
 * 全站路由配置
 *
 * meta参数说明
 * keepAlive是否缓冲页面
 * isTab是否加入到tag导航
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store/';
import PageRouter from './page/'
import ViewsRouter from './views/'
import DynamicRouter from './router-plugin';

Vue.use(VueRouter)

// 创建默认路由
export const createRouter = () => new VueRouter({
  routes: [...PageRouter, ...ViewsRouter]
})

const Router = createRouter()
DynamicRouter.install(Vue, Router, Store);
Router.$dynamicRouter.formatRoutes(Store.getters.MenuAll, true);

// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// 重置路由
export function resetRouter () {
  const newRouter = createRouter()
  Router.matcher = newRouter.matcher
  DynamicRouter.install(Vue, Router, Store);
  Store.commit('SET_MENUALL', [])
}

export default Router
