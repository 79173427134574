import util from '@/utils/common'

export default {
    SetTheme,
    SetThemeColor,
    LoadFontStyle
}

// 设置主题
function SetTheme(name) {
    document.body.className = name
}

const version = require('element-ui/package.json').version
const ORIGINAL_THEME = util.GetStore('colorName')
let chalk = ''
// 设置主题颜色
function SetThemeColor(val, oldVal) {
    if (typeof val !== 'string') return
    const head = document.getElementsByTagName('head')[0]
    const themeCluster = getThemeCluster(val.replace('#', ''))
    const originalCluster = getThemeCluster(oldVal.replace('#', ''))
    const getHandler = (variable, id) => {
        return () => {
            const originalCluster = getThemeCluster(ORIGINAL_THEME.replace('#', ''))
            const newStyle = updateStyle('chalk', originalCluster, themeCluster)

            let styleTag = document.getElementById(id)
            if (!styleTag) {
                styleTag = document.createElement('style')
                styleTag.setAttribute('id', id)
                head.appendChild(styleTag)
            }
            styleTag.innerText = newStyle
        }
    }

    const chalkHandler = getHandler('chalk', 'chalk-style')

    if (chalk === '') {
        const url = `https://unpkg.com/element-ui@${version}/lib/theme-chalk/index.css`
        getCSSString(url, chalkHandler, 'chalk')
    } else {
        chalkHandler()
    }

    const link = [].slice.call(document.getElementsByTagName('head')[0].getElementsByTagName('link'))
    for (let i = 0; i < link.length; i++) {
        const style = link[i]
        if (style.href.includes('css')) {
            getCSSString(style.href, (innerText) => {
                const originalCluster = getThemeCluster(ORIGINAL_THEME.replace('#', ''))
                const newStyle = updateStyle(innerText, originalCluster, themeCluster)
                let styleTag = document.getElementById(i)
                if (!styleTag) {
                    styleTag = document.createElement('style')
                    styleTag.id = i
                    styleTag.innerText = newStyle
                    head.appendChild(styleTag)
                }
            })
        }
    }

    // const styles = [].slice.call(document.querySelectorAll('style'))
    //
    // styles.forEach((style) => {
    //     if (style.innerText.toString().indexOf('DevExtreme') === -1) {
    //         const { innerText } = style
    //         if (typeof innerText !== 'string') return
    //         style.innerText = updateStyle(innerText, originalCluster, themeCluster)
    //     }
    // })
}

function getThemeCluster(theme) {
    const tintColor = (color, tint) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)

        if (tint === 0) {
            // when primary color is in its rgb space
            return [red, green, blue].join(',')
        } else {
            red += Math.round(tint * (255 - red))
            green += Math.round(tint * (255 - green))
            blue += Math.round(tint * (255 - blue))

            red = red.toString(16)
            green = green.toString(16)
            blue = blue.toString(16)

            return `#${red}${green}${blue}`
        }
    }

    const shadeColor = (color, shade) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)

        red = Math.round((1 - shade) * red)
        green = Math.round((1 - shade) * green)
        blue = Math.round((1 - shade) * blue)

        red = red.toString(16)
        green = green.toString(16)
        blue = blue.toString(16)

        return `#${red}${green}${blue}`
    }

    const clusters = [theme]
    for (let i = 0; i <= 9; i++) {
        clusters.push(tintColor(theme, Number((i / 10).toFixed(2))))
    }
    clusters.push(shadeColor(theme, 0.1))
    return clusters
}

function getCSSString(url, callback, variable) {
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
            if (variable === 'chalk') {
                chalk = xhr.responseText.replace(/@font-face{[^}]+}/, '')
            }
            callback(xhr.responseText)
        }
    }
    xhr.open('GET', url)
    xhr.send()
}

function updateStyle(style, oldCluster, newCluster) {
    let newStyle = style
    oldCluster.forEach((color, index) => {
        newStyle = newStyle.replace(new RegExp(color, 'ig'), newCluster[index])
    })
    return newStyle
}

// 阿里云字体
function LoadFontStyle(url) {
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url
    const head = document.getElementsByTagName('head')[0]
    head.appendChild(link)
}
