import router from '@/router'
import { resetRouter } from '@/router'

import { Message } from 'element-ui'
import { MessageBox } from 'element-ui'
import { Notification } from 'element-ui'
import { Loading } from 'element-ui'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { setStore, getStore, removeStore, clearStore, getAllStore } from '@/utils/store'
import menu from '@/api/menu.js'
import store from "../store";

export default {
    MsgBox,
    Notice,
    Confirm,
    ShowProgress,
    HideProgress,
    ShowLoading,
    HideLoading,
    IsNull,
    DeepClone,
    NavigateTo,
    ResetRouter,
    OpenWindow,
    GetQuery,
    IsMobile,
    SetStore,
    GetStore,
    RemoveStore,
    ClearAllStore,
    GetAllStore,
    ObjToForm,
    ObjDiff,

    loginStore
}

function StatusType(status) {
    let type = 'info'
    if (status === 0) type = 'warning'
    if (status === 1) type = 'success'
    if (status === -1) type = 'error'
    return type
}

function MsgBox(message, status) {
    let type = StatusType(status)
    Message({
        type: type,
        message: message,
        showClose: true,
        duration: 10000
    })
}

function Notice(message, status) {
    let type = StatusType(status)
    Notification({
        type: type,
        title: '提示',
        message: message,
        duration: 10000
    })
}

function Confirm(message, callback) {
    MessageBox.confirm(message, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        callback: callback
    })
}

function ShowProgress() {
    NProgress.configure({ showSpinner: false })
    NProgress.start()
}

function HideProgress() {
    NProgress.done()
}

function ShowLoading() {
    Loading.service({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}

function HideLoading() {
    let loadingInstance = Loading.service({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
    loadingInstance.close()
}

/**
 * 判断是否为空
 */
function IsNull(val) {
    if (typeof val == 'boolean') {
        return false
    }
    if (typeof val == 'number') {
        return false
    }
    if (val instanceof Array) {
        if (val.length == 0) return true
    } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') return true
    } else {
        if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true
        return false
    }
    return false
}

function GetObjType(obj) {
    let toString = Object.prototype.toString
    let map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    }
    if (obj instanceof Element) {
        return 'element'
    }
    return map[toString.call(obj)]
}

/**
 * 对象深拷贝
 */
function DeepClone(data) {
    let type = GetObjType(data)
    let obj
    if (type === 'array') {
        obj = []
    } else if (type === 'object') {
        obj = {}
    } else {
        //不再具有下一层次
        return data
    }
    if (type === 'array') {
        for (let i = 0, len = data.length; i < len; i++) {
            obj.push(DeepClone(data[i]))
        }
    } else if (type === 'object') {
        for (let key in data) {
            obj[key] = DeepClone(data[key])
        }
    }
    return obj
}

function isURL(s) {
    // console.log('ISURL', s)
    if (s === undefined) return false
    if (s.includes('html')) return true
    return /^http[s]?:\/\/.*/.test(s)
}

function NavigateTo(path, query) {
    if (isURL(path)) {
        path = `/iframe/urlPath?src=` + path
        router.push({ path: path }).catch((err) => {
            err
        })
    }
    if (!query)
        router.push({ path: path }).catch((err) => {
            err
        })
    else
        router.push({ path: path, query: query }).catch((err) => {
            err
        })
}

function ResetRouter() {
    resetRouter()
}

/**
 * 打开小窗口
 */
function OpenWindow(url, title, w, h) {
    // Fixes dual-screen position                            Most browsers       Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

    const left = width / 2 - w / 2 + dualScreenLeft
    const top = height / 2 - h / 2 + dualScreenTop
    const newWindow = window.open(
        url,
        title,
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left
    )

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus()
    }
}

function GetQuery(name) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    let r = window.location.search.substr(1).match(reg) //search,查询？后面的参数，并匹配正则
    if (r != null) return unescape(r[2])
    return null
}

function IsMobile() {
    let mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return mobile != null
}

function SetStore(pName, pValue) {
    let params = { name: pName, value: pValue }
    const { name, value, type } = params
    setStore({
        name: name,
        content: value,
        type: type
    })
}

function GetStore(pName) {
    let params = { name: pName }
    const { name, type } = params
    const content = getStore({
        name: name,
        type: type
    })
    return content
}

function RemoveStore(pName) {
    let params = { name: pName }
    const { name, type } = params
    removeStore({ name, type })
}

function ClearAllStore(params = {}) {
    clearStore(params)
}

function GetAllStore(params = {}) {
    const list = getAllStore(params)
    console.log(list)
}

function ObjToForm(obj) {
    let result = []
    Object.keys(obj).forEach((ele) => {
        result.push(`${ele}=${obj[ele]}`)
    })
    return result.join('&')
}

function ObjDiff(obj1, obj2) {
    delete obj1.close
    let o1 = obj1 instanceof Object
    let o2 = obj2 instanceof Object
    if (!o1 || !o2) {
        /*  判断不是对象  */
        return obj1 === obj2
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false
        //Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
    }

    for (let attr in obj1) {
        let t1 = obj1[attr] instanceof Object
        let t2 = obj2[attr] instanceof Object
        if (t1 && t2) {
            return ObjDiff(obj1[attr], obj2[attr])
        } else if (obj1[attr] !== obj2[attr]) {
            return false
        }
    }
    return true
}

function loginStore(clientID, resp){
    SetStore("CurrentClientID", clientID)

    // 缓存参数
    store.commit('SET_TOKEN', resp.Token)
    store.commit('SET_REFRESHTOKEN', resp.RefreshToken)
    store.commit('SET_USERINFO', resp.UserInfo)
    store.commit('DEL_ALL_TAG')

    // 设置动态路由
    let menuArray = []
    switch (clientID){
        //管理员
        case "Admin":
            menuArray = menu.adminMenu
            break

        //企业机构
        case "Enterprise":
            menuArray = menu.enterpriseMenu
            break

        //调解机构
        case "Mediator":
            menuArray = menu.mediatorMenu
            break

        //社会组织
        case "Community":
            menuArray = menu.communityMenu
            break

        default:
            Notice("（前端）客户端异常！", -1)
            return
    }
    ResetRouter()
    router.$dynamicRouter.formatRoutes(menuArray, true)
    store.commit('SET_MENU', menuArray)
    store.commit('SET_MENUALL', menuArray)

    return menuArray[0]['Path']
}
