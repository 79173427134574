import util from '@/utils/common'
import theme from '@/utils/theme'

const common = {
    state: {
        collapsed: false,
        showTheme: true,
        showColor: true,
        colorName: util.GetStore('colorName') || '#f86d85',
        themeName: util.GetStore('themeName') || 'default'
    },
    mutations: {
        SET_COLLAPSE: (state) => {
            state.collapsed = !state.collapsed
        },
        SET_COLOR_NAME: (state, colorName) => {
            state.colorName = colorName
            let oldVal = util.GetStore('colorName')
            util.SetStore('colorName', state.colorName)
            theme.SetThemeColor(colorName, oldVal)
        },
        SET_THEME_NAME: (state, themeName) => {
            state.themeName = themeName
            util.SetStore('themeName', state.themeName)
            // theme.SetTheme('theme-white')
            theme.SetTheme(state.themeName)
        }
    }
}
export default common
