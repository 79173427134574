<template>
    <div class="avue-top">
        <div class="top-bar__left">
            <div class="avue-breadcrumb" :class="[{ 'avue-breadcrumb--active': collapsed }]">
                <i class="icon-navicon" @click="setCollapse"></i>
            </div>
        </div>
        <div class="top-bar__title">
<!--            <div class="top-bar__item top-bar__item&#45;&#45;show">-->
<!--                <nav-menu ref="topMenu"></nav-menu>-->
<!--            </div>-->
<!--            <span class="top-bar__item">-->
<!--                <nav-search></nav-search>-->
<!--            </span>-->
        </div>
        <div class="top-bar__right">
<!--            <el-tooltip effect="dark" content="消息通知" placement="bottom">-->
<!--            <div class="top-bar__item top-bar__item&#45;&#45;show">-->
<!--            <nav-notice>A</nav-notice>-->
<!--            </div>-->
<!--            </el-tooltip>-->
            <el-tag effect="dark" color="#0948A5">{{currentClientName}}</el-tag>

            <img class="top-bar__img" :src="UserInfo.Avatar || defaultAvatar" />
            <el-dropdown>
                <span class="el-dropdown-link">
                    {{ UserInfo.Name }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <router-link to="/">首页</router-link>
                    </el-dropdown-item>
                    <el-dropdown-item>
<!--                        <router-link to="/info/index">个人信息</router-link>-->
<!--                        <div @click="dialogVisible=true">重置密码</div>-->
                        <div @click="onShowReset">重置密码</div>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="currentClientID==='Admin'">
                        <div @click="onOpenBlockChain">区块链存证</div>
                    </el-dropdown-item>
<!--                    <el-dropdown-item>-->
<!--                        <router-link to="/info/setting">个人设置</router-link>-->
<!--                    </el-dropdown-item>-->
                    <el-dropdown-item @click.native="logout" divided>退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <reset ref="reset" @onConfirmReset="onConfirmReset"/>

<!--        <el-dialog-->
<!--            title="重置密码"-->
<!--            center-->
<!--            width="30%"-->
<!--            :show-close="false"-->
<!--            :visible.sync="dialogVisible"-->
<!--            :close-on-click-modal="false"-->
<!--            >-->
<!--            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">-->
<!--                <el-form-item label="旧的密码" prop="Password">-->
<!--                    <el-input clearable v-model="ruleForm.Password"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="新的密码" prop="NewPassword">-->
<!--                    <el-input clearable type="password" v-model="ruleForm.NewPassword"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="确认密码" prop="ConfirmPassword">-->
<!--                    <el-input clearable type="password" v-model="ruleForm.ConfirmPassword" @paste.native.capture.prevent="{}"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item>-->
<!--                    <el-button type="primary" style="width: 49%" @click="onReset('ruleForm')">确认</el-button>-->
<!--                    <el-button type="primary" style="width: 49%" @click="onCancel('ruleForm')">取消</el-button>-->
<!--                </el-form-item>-->
<!--            </el-form>-->
<!--        </el-dialog>-->
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import navMenu from './nav-menu'
// import navSearch from './nav-search'
import Reset from "../../login/reset";

export default {
    components: {
        Reset,
        navMenu,
        // navSearch
        // navNotice
    },
    name: 'navbar',
    data() {
        return {
            dialogVisible: false,
            defaultAvatar: require('@/assets/icon/avatar.svg'),
            ruleForm:{
                Password: '',
                NewPassword: '',
                ConfirmPassword: '',
            },
            rules:{
                Password: [
                    { required: true, message: '请输入旧的密码', trigger: 'blur' },
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符', trigger: 'blur' }
                ],
                NewPassword: [
                    { required: true, message: '请输入新的密码', trigger: 'blur' },
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符', trigger: 'blur' }
                ],
                ConfirmPassword: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符', trigger: 'blur' }
                ],
            },
            currentClientID: "",
            currentClientName: ""
        }
    },
    filters: {},
    created() {},
    mounted() {
        this.currentClientID = this.$Util.GetStore("CurrentClientID")
        switch (this.currentClientID){
            case "Admin":
                this.currentClientName = "管理员"
                break
            case "Community":
                this.currentClientName = "社会组织"
                break
            case "Enterprise":
                this.currentClientName = "企业机构"
                break
            case "Mediator":
                this.currentClientName = "调解机构"
                break
        }
    },
    computed: {
        ...mapGetters(['UserInfo', 'collapsed'])
    },
    methods: {
        setCollapse() {
            this.$store.commit('SET_COLLAPSE')
        },
        logout() {
            this.$Util.Confirm('退出系统, 是否继续?', () => {
                this.$store.commit('SET_SID', '')
                this.$store.commit('SET_MENUALL', [])
                this.$store.commit('SET_MENU', [])
                this.$store.commit('DEL_ALL_TAG')
                this.$Util.ResetRouter()
                let clientID = this.$Util.GetStore("CurrentClientID")
                if(clientID === 'Admin')
                    this.$Util.NavigateTo('/admin')
                else
                    this.$Util.NavigateTo('/login')
            })
        },
        // //重置
        // onReset(formName){
        //     this.$refs[formName].validate((data) => {
        //         if (data) {
        //             this.$Util.ShowLoading()
        //             this.$API.PostService('AdminUser/ResetPassword', this.ruleForm, () => {
        //                 this.$alert('请重新登陆', '修改成功', {
        //                     confirmButtonText: '确定',
        //                     showClose: false,
        //                     callback: () => {
        //                         this.$store.commit('SET_SID', '')
        //                         this.$store.commit('SET_MENUALL', [])
        //                         this.$store.commit('SET_MENU', [])
        //                         this.$store.commit('DEL_ALL_TAG')
        //                         this.$Util.ResetRouter()
        //                         this.$Util.HideLoading()
        //                         this.$Util.NavigateTo('/login')
        //                     }
        //                 });
        //             })
        //         } else {
        //             console.log('error submit!!');
        //             return false;
        //         }
        //     });
        // },
        onShowReset(){
            let clientID = this.$Util.GetStore("CurrentClientID")
            let userInfo = this.$Util.GetStore("UserInfo")

            this.$refs.reset.onShowDialog(this.$Config.api.clientID + clientID, clientID + "User", userInfo.Number, '修改密码', null)
        },

        onOpenBlockChain(){
            window.open('http://119.91.92.153/')
        },

        onConfirmReset(){
            this.$Util.HideLoading()

            this.$alert('请重新登陆', '修改成功', {
                confirmButtonText: '确定',
                showClose: false,
                callback: () => {
                    this.$store.commit('SET_SID', '')
                    this.$store.commit('SET_MENUALL', [])
                    this.$store.commit('SET_MENU', [])
                    this.$store.commit('DEL_ALL_TAG')
                    this.$Util.ResetRouter()
                    this.$Util.NavigateTo('/login')
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped></style>
