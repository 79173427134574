<template>
    <div class="avue-logo">
        <transition name="fade">
            <span v-if="collapsed" class="avue-logo_subtitle" key="0">
                {{ indexLogo }}
            </span>
        </transition>
        <transition-group name="fade">
            <template v-if="!collapsed">
                <span class="avue-logo_title" key="1">{{ indexTitle }} </span>
            </template>
        </transition-group>
    </div>
</template>

<script>
import config from '@/api/config'
import { mapGetters } from 'vuex'
export default {
    name: 'logo',
    data() {
        return {
            indexLogo: config.login.indexLogo,
            indexTitle: config.login.indexTitle
        }
    },
    created() {},
    computed: {
        ...mapGetters(['collapsed'])
    },
    methods: {}
}
</script>

<style lang="scss">
.fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter-active {
    transition: opacity 2.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.avue-logo {
    position: fixed;
    top: 0;
    left: 0;
    /*width: 240px;*/
    width: 180px;
    /*height: 64px;*/
    /*line-height: 64px;*/
    height: 45px;
    line-height: 45px;
    background-color: #20222a;
    font-size: 20px;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    color: rgba(255, 255, 255, 0.8);
    z-index: 1024;
    &_title {
        display: block;
        text-align: center;
        font-weight: 300;
        font-size: 20px;
    }
    &_subtitle {
        display: block;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
    }
}
</style>
