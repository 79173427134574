<template>
    <div class="menu-wrapper">
        <template v-for="item in menu">
            <el-menu-item v-if="$Util.IsNull(item['Children']) && !item['Hidden']" :index="item['Path']" @click="open(item)" :key="item['ID']" :class="{ 'is-active': vaildActive(item) }">
                <i :class="item['Icon']"></i>
                <span slot="title" :alt="item['Path']">{{ item['Title'] }}</span>
            </el-menu-item>
            <el-submenu v-else-if="!$Util.IsNull(item['Children']) && !item['Hidden']" :index="item['Path']" :key="item['ID']">
                <template slot="title">
                    <i :class="item['Icon']"></i>
                    <span slot="title" :class="{ 'el-menu--display': collapse && first }">{{ item['Title'] }}</span>
                </template>
                <template v-for="(child, cindex) in item['Children']">
                    <el-menu-item
                        :index="child['ID'].toString()"
                        @click="open(child)"
                        :class="{ 'is-active': vaildActive(child) }"
                        v-if="$Util.IsNull(child['Children']) && !child['Hidden']"
                        :key="child['Name']"
                    >
                        <i :class="child['Icon']"></i>
                        <span slot="title">{{ child['Title'] }}</span>
                    </el-menu-item>
                    <sidebar-item v-else :menu="[child]" :key="cindex" :screen="screen" :collapse="collapse"></sidebar-item>
                </template>
            </el-submenu>
        </template>
    </div>
</template>
<script>
export default {
    name: 'sidebarItem',
    data() {
        return {}
    },
    props: {
        menu: {
            type: Array
        },
        screen: {
            type: Number
        },
        first: {
            type: Boolean,
            default: false
        },
        collapse: {
            type: Boolean
        }
    },
    methods: {
        vaildActive(item) {
            return this.$route.name === item['Name']
        },
        open(item) {
            this.$Util.NavigateTo(item['Path'])
        }
    }
}
</script>
