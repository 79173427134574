<template>
    <el-dialog
        :title="titleStr"
        center
        width="30%"
        :show-close="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        >
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="新的密码" prop="NewPassword">
                <el-input clearable type="password" v-model="ruleForm.NewPassword"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="ConfirmPassword">
                <el-input clearable type="password" v-model="ruleForm.ConfirmPassword" @paste.native.capture.prevent="{}"></el-input>
            </el-form-item>
            <el-form-item v-if="titleStr === '设置密码'">
                <el-button type="primary" style="width: 100%" @click="onReset('ruleForm')">确认</el-button>
            </el-form-item>
            <el-form-item v-else>
                <el-button type="primary" style="width: 40%" @click="onReset('ruleForm')">确认</el-button>
                <el-button type="primary" style="width: 40%" @click="onCancel('ruleForm')">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>

export default {
    components: {},
    name: 'reset',
    props: { },
    data() {
        return {
            dialogVisible: false,
            titleStr: '重置密码',
            entity: '',
            //注册时需要跳转的路径
            menuPath: null,

            ruleForm:{
                Account: '',
                NewPassword: '',
                ConfirmPassword: '',
                ClientID: ''
            },
            rules:{
                NewPassword: [
                    { required: true, message: '请输入新的密码', trigger: 'blur' },
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符', trigger: 'blur' }
                ],
                ConfirmPassword: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符', trigger: 'blur' }
                ],
            }
        }
    },

    methods: {
        //确认修改
        onReset(formName){
            let _this = this
            _this.$refs[formName].validate((data) => {
                if (data) {
                    if(_this.ruleForm.NewPassword !== _this.ruleForm.ConfirmPassword){
                        _this.$Util.MsgBox('密码不一致！', -1)
                        return false
                    }
                    // 修改密码
                    _this.$Util.ShowLoading()
                    _this.$API.PostService(this.entity + '/ResetPassword', _this.ruleForm, () => {
                        _this.$Util.HideLoading()
                        _this.dialogVisible = false

                        _this.$emit('onConfirmReset', this.menuPath)
                    })
                } else {
                    return false;
                }
            });
        },

        /**
         * 打开修改密码提示框
         * @param clientID 客户端
         * @param entity 实体类名
         * @param phone 手机号
         * @param title 标题，注册用户进来显示【设置密码】强制要求填写密码
         */
        onShowDialog(clientID, entity, phone, title, menuPath=null){
            if(this.$Util.IsNull(phone)){
                this.$Util.MsgBox('手机号不能为空！', -1)
                return
            }

            this.titleStr = title
            this.entity = entity
            this.menuPath = menuPath

            this.ruleForm = {
                Account: phone,
                ClientID: clientID,
                NewPassword: '',
                ConfirmPassword: ''
            }
            this.dialogVisible = true
        },
        //取消
        onCancel(formName){
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            // this.$emit('onCancelReset', false)
        }
    }
}
</script>

<style lang="scss" scoped></style>
