const fileUrl = "https://www.zhizhixy.com/files/zhizhi/"
//正式
const webUrl = "https://www.zhizhixy.com/zz/"

export default {
    login: {
        title: '知信链-企业应收账款管理平台',
        // title: '全国中小企业应收账管理平台Test',
        indexLogo: '知之',
        indexTitle: '知信链',
        version: 'v1.0.0'
    },
    api: {
        fileUrl: fileUrl,
        webUrl: webUrl,
        clientID: 'ZZ-',
        // clientID: 'ZZTest-',
        entityUrl: webUrl + "entity/",
        serviceUrl: webUrl + "service/",
    },
    setup: {
        themeColor: '#0948A5',
        statusOption: [
            { label: "全部", value: "1==1" },
            { label: "待提交", value: 'Status=="A"' },
            { label: "已提交", value: 'Status=="B"' },
            { label: "已受理", value: 'Status=="C"' },
            { label: "已驳回", value: 'Status=="D"' },
            { label: "已告知", value: 'Status=="E"' },
            { label: "已结案", value: 'Status=="F"' },
            { label: "已关闭", value: 'Status=="G"' },
        ]
    },
}
