import Layout from '@/page/index/'
export default [
    // {
    //     path: '/register',
    //     component: Layout,
    //     redirect: '/main/register',
    //     children: [
    //         {
    //             path: 'register',
    //             name: '注册',
    //             component: () => import(/* webpackChunkName: "views" */ '@/views/main/register'),
    //             meta: {
    //                 title: '注册'
    //             }
    //         }
    //     ]
    // },
    // {
    //     path: '/profile',
    //     component: Layout,
    //     redirect: '/profile/info',
    //     children: [
    //         {
    //             path: 'info',
    //             name: '个人信息',
    //             component: () => import(/* webpackChunkName: "views" */ '@/views/system/profile/userInfo'),
    //             meta: {
    //                 title: '个人信息'
    //             }
    //         },
    //         {
    //             path: 'setting',
    //             name: '个人设置',
    //             component: () => import(/* webpackChunkName: "views" */ '@/views/system/profile/userSetting'),
    //             meta: {
    //                 title: '个人设置'
    //             }
    //         }
    //     ]
    // }
]
