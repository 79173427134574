import _ from 'lodash'

let RouterPlugin = function() {
    this.$router = null
    this.$store = null
}
RouterPlugin.install = function(vue, router, store) {
    this.$router = router
    this.$store = store

    function isURL(s) {
        // console.log('ISURL', s)
        if (s === undefined) return false
        if (s.includes('html')) return true
        return /^http[s]?:\/\/.*/.test(s)
    }

    this.$router.$dynamicRouter = {        
        routerList: [],
        refer: this,
        //动态路由
        formatRoutes: function(menus = [], top) {
            const routers = []
            if (menus.length === 0) return
            for (let i = 0; i < menus.length; i++) {
                const menu = menus[i]
                if (this.routerList.includes(menu['Path'])) continue

                let parentId = menu['ParentID']
                let name = menu['Name']
                let path = menu['Path']
                let component = menu['Component']
                let icon = menu['Icon']
                let title = menu['Title']
                let type = menu['Type']
                let level = menu['Level']
                let children = menu['Children']
                let meta = {
                    title: title,
                    keepAlive: true //type === 'View' || type === 'Page'
                }

                const hasChild = children && children.length !== 0

                const router = {
                    path: path,
                    component(resolve) {                        
                        if ((type === 'Group' || type === 'View') && level === 1) {
                            require(['../page/index/home'], resolve)
                            return
                        } else if ((type === 'Group' && level > 1) || (type === 'Page' || type === 'Url')) {
                            require(['../page/index/layout'], resolve)
                            return
                        } else if (type === 'View' && level > 1) {
                            require([`../${component}.vue`], resolve)
                        }
                    },
                    name: name,
                    icon: icon,
                    meta: meta,
                    redirect: (() => {
                        if (type === 'View' && level === 1) return `${path}/index`
                        else return ''
                    })(),
                    // 处理是否为一级路由
                    children: type !== 'Group' && !hasChild
                        ? (() => {
                            if (type === 'View' && level === 1) {
                                return [
                                    {
                                        component(resolve) {
                                            require([`../${component}.vue`], resolve)
                                        },
                                        icon: icon,
                                        name: name,
                                        meta: meta,
                                        path: 'index'
                                    }
                                ]
                            }
                            return []
                        })()
                        : (() => {
                            return this.formatRoutes(children, false)
                        })()
                }
                routers.push(router)
            }

            if (top) {
                if (routers.length === 0) return
                if (!this.routerList.includes(routers[0]['path'])) {
                    this.refer.$router.addRoutes(routers)
                    this.routerList.push(routers[0]['path'])
                }
            } else {
                return routers
            }
        }
    }
}
export default RouterPlugin
