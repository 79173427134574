<template>
<!--    <div>-->
<!--        <div class="top-box">-->
<!--&lt;!&ndash;            <div class="top-title">{{ title }}</div>&ndash;&gt;-->

<!--&lt;!&ndash;            <div class="top-box">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <el-image class="top-logo" src="https://erp.miaosou.club/files/zhizhi/image/logo.png" />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                <h1 class="top-title">{{ title }}</h1>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="nav">-->
<!--            <ul>-->
<!--                <li @click="toPage('/')" style="cursor: pointer;">-->
<!--                    <a>首页信息</a>-->
<!--                </li>-->
<!--                <li @click="toPage('policy-list')" style="cursor: pointer;">-->
<!--                    <a>政策法规</a>-->
<!--                </li>-->
<!--                <li @click="toPage('scope-list')" style="cursor: pointer;">-->
<!--                    <a>服务范围</a>-->
<!--                </li>-->
<!--                <li @click="toPage('about')" style="cursor: pointer;">-->
<!--                    <a>关于我们</a>-->
<!--&lt;!&ndash;                    <a href="#">更多相关</a>&ndash;&gt;-->
<!--&lt;!&ndash;                    <ul class="son">&ndash;&gt;-->
<!--&lt;!&ndash;                        <li>&ndash;&gt;-->
<!--&lt;!&ndash;                            <a @click="toPage('about')" style="cursor: pointer;">关于我们</a>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                            <a href="/#/about">关于我们</a>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                        </li>&ndash;&gt;-->
<!--&lt;!&ndash;                    </ul>&ndash;&gt;-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->
<!--    </div>-->

<!--new ui-->
    <div style="background-color: #F1F6FF;margin-bottom: -100px">
        <el-row type="flex" class="page-bsy-menu" justify="center">
            <el-col :span="5">
                <div style="width: 130px;margin: 0 auto;padding-top: 11px">
                    <el-image :src="fileUrl+'image/index/logo.png'"/>
                </div>
            </el-col>
            <el-col :span="14">
                <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect"
                         background-color="#F1F6FF" text-color="#0849A5" active-text-color="#00B9FE"
                         style="width: 100%;text-align: center;font-weight: bold">
                    <el-menu-item index="1" style="width: 20%;font-size: 20px;" @click="toPage('/')">首页</el-menu-item>
                    <el-menu-item index="2" style="width: 20%;font-size: 20px;" @click="toPage('login')">申请调解</el-menu-item>
                    <el-menu-item index="3" style="width: 20%;font-size: 20px;" @click="toPage('scope-list')">服务范围</el-menu-item>
                    <el-menu-item index="4" style="width: 20%;font-size: 20px;" @click="toPage('policy-list')">政策法规</el-menu-item>
                    <el-menu-item index="5" style="width: 20%;font-size: 20px;" @click="toPage('about')">关于我们</el-menu-item>
                </el-menu>
            </el-col>
            <el-col :span="5">
                <div style="width: 130px;margin: 0 auto;" @click="toPage('login')">
                    <el-image style="padding-top: 12px" :src="fileUrl+'image/index/default-avatar.png'"/>
                    <el-link :underline="false" style="font-weight:bold;font-size: 17px;color: #0849A5;margin-top: -22px;margin-left: 10px">未登录</el-link>
                </div>
            </el-col>
        </el-row>
        <el-image :src="fileUrl+'image/index/index_top.png'" style="width: 100%;" fit="contain"/>
    </div>
</template>

<script>
export default {
    name: 'bsy-menu',
    components: {},
    props:{
        menuList:{
            type: Array,
            default: () => [

            ]
        },
    },
    data() {
        return {
            title: this.$Config.login.title,
            fileUrl: this.$Config.api.fileUrl,
            activeIndex: '1',
        }
    },
    mounted() {

    },
	methods: {
        toPage(router){
            // this.$router.push( { name: router} )
            this.$Util.NavigateTo(router)
        },
        openWindow() {
            window.open("https://www.creditchina.gov.cn/xinyongfuwu/tongyishehuixinyongdaimachaxunzhuanlan/")
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
	}
}
</script>
<style lang="scss" scoped>
*{
    /*设置通用样式*/
    padding: 0;
    margin: 0;
}
.page-bsy-menu{
    margin: 0 auto;
    width:1200px;
}

//.top-box{
//    background: url('https://erp.miaosou.club/files/zhizhi/image/index_top.png');
//    height: 250px;
//    background-size: cover;
//
//    //.top-box{
//    //    margin: 0 auto;
//    //    width:1200px;
//    //
//    //    .top-logo{
//    //        margin-top: 100px;
//    //        height: 100px;
//    //        width: 100px
//    //    }
//    //
//    //    .top-title{
//    //        //margin-left: 100px;
//    //        text-align: center;
//    //        padding-top: 115px;
//    //    }
//    //}
//
//    .top-title{
//        //margin-left: 100px;
//        text-align: center;
//        padding-top: 120px;
//        font-size: 30px;
//        font-weight: bold;
//    }
//}
//.nav{
//    width: 100%;
//    height:60px;
//    /*margin-top: 200px;*/
//    background-color:#0948A5;
//    box-shadow: 0 0 5px #073780;
//}
//.nav>ul{
//    /*子元素选择器（>号）设置第一导航栏样式*/
//    width: 1200px;
//    height:60px;
//    margin:0px auto;
//}
//.nav>ul>li{
//    float: left;/*设置li浮动*/
//    width: 300px;
//    height:100%;
//    list-style: none;/*取消li符号*/
//    padding:0; margin:0;
//    text-align: center;
//    position:relative;
//}
//.nav>ul>li:hover{
//    /*给li设置鼠标划入背景颜色*/
//    background-color: #073780;
//    z-index: 3;
//}
//.nav>ul>li a{
//    /*设置a链接样式*/
//    color: #FFFFFF;
//    line-height: 60px;
//    font-size: 20px;
//    font-weight: bold;
//    /*text-decoration: none;!*取消a链接下划线*!*/
//}
//.nav>ul>li:hover .son{
//    /*鼠标划入li时显示第二列表*/
//    text-align: center;
//    background-color:#0948A5;
//    display: block;
//    animation:move 0.5s 0s;/*设置划入导航栏下拉的动画效果*/
//}
//@keyframes move{
//    /*设置animation动画参数*/
//    0%{
//        /*动画开始时*/
//        /*height:0px;*/
//        max-height:0px;
//    }
//    100%{
//        /*动画结束时*/
//        /*height: 120px;*/
//        max-height: 180px;
//    }
//}
//
//.son{
//    /*设置第二导航栏样式*/
//    list-style: none;
//    display: none;/*隐藏第二列表*/
//    /*text-align: left;*/
//}
//.son li:hover{
//    /*当鼠标划入时将第li背景颜色*/
//    background-color: #073780;
//}
</style>
