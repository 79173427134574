<template>
    <div style="background-color: #F1F6FF">
        <div class="page-router-top">
            <bsy-menu />
        </div>

        <div class="page-router-main">
            <div class="page-router-body">
                <keep-alive>
                    <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive" />
                </keep-alive>
                <router-view class="avue-view" :key="$route.fullPath" v-if="!$route.meta.keepAlive" />
            </div>
        </div>

        <div class="page-router-bottom">
            <bsy-bottom />
        </div>

<!--        <bsy-menu-right />-->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BsyMenu from "@/page/index/navbar/bsy-menu";
import BsyBottom from "@/page/index/navbar/bsy-bottom";
// import BsyMenuRight from "@/page/index/navbar/bsy-menu-right";

export default {
    // 资源首页
    name: 'index',
    components: {BsyBottom, BsyMenu},
    provide() {
        return {
            index: this
        }
    },
    data() {
        return {}
    },
    computed: mapGetters(['Menu', 'collapsed']),
    props: [],
    mounted() {
        this.$store.commit('SET_THEME_NAME', 'vip')
        this.$store.commit('SET_COLOR_NAME', '#f86d85')
    },
    methods: {
        showCollapse() {
            this.$store.commit('SET_COLLAPSE')
        },
        //打开菜单
        openMenu(item = {}) {
            if (this.$Util.IsNull(item)) return
            if (this.$Util.IsNull(item.ID)) return

            if(this.$Menu.menu === 'local'){
                // let sideMenu = this.$Menu.sideMenu.filter((subItem) => {
                //     return subItem.ParentID === item.ID
                // })

                // this.$router.$dynamicRouter.formatRoutes(sideMenu, true)
                // this.$store.commit('SET_MENU', sideMenu)
                // if (this.$Menu.sideMenu.length > 0 && !this.$Util.IsNull(item['Path']) && this.$Util.IsNull(item['TagClick'])) {
                //     this.$Util.NavigateTo(item['Path'])
                // }
            }else{
                // this.$AdminApi.PostMethod('Menu', 'GetSubMenu', { MenuID: item.ID }, (resp) => {
                //     let sideMenu = resp.Data
                //     this.$router.$dynamicRouter.formatRoutes(sideMenu, 0)
                //     this.$store.commit('SET_MENU', sideMenu)
                //     if (resp.Data.length > 0 && !this.$Util.IsNull(item['Path']) && this.$Util.IsNull(item['TagClick'])) {
                //         this.$Util.NavigateTo(item['Path'])
                //     }
                // })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.page-router-top{
    position: relative;
    z-index: 10;
}
.page-router-main{
    position: relative;
    z-index: 100;
    margin: 0 auto;
    width:1200px;

    .page-router-body{
        z-index: 200;
    }
}
.page-router-bottom{
    z-index: 10;
}
</style>
