<template>
    <div class="top-menu">
        <el-menu :default-active="activeIndex" mode="horizontal" text-color="#333">
            <template v-for="(item, index) in items">
                <el-menu-item :index="item.ID + ''" @click.native="openMenu(item)" :key="index">
                    <template slot="title">
                        <i :class="item.Icon"></i>
                        <span>{{ item.Title }}</span>
                    </template>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'nav-menu',
    data() {
        return {
            activeIndex: '0',
            items: []
        }
    },
    inject: ['index'],
    created() {
        this.getMenu()
    },
    computed: {
        ...mapGetters(['SID'])
    },
    methods: {
        openMenu(item) {
            this.$Util.NavigateTo(item['Path'])
            // this.index.openMenu(item)
        },
        getMenu() {
            if(this.$Menu.menu === 'local'){
                if (this.$Util.IsNull(this.$Menu.topMenu)) return
                this.items = this.$Menu.topMenu
            }else{
                // this.$AdminApi.PostMethod('Menu', 'GetTopMenu', { SID: this.SID }, (resp) => {
                //     this.items = resp.Data
                // })
            }
        }
    }
}
</script>
