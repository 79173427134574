import util from '@/utils/common.js'
import request from '@/utils/request'
import config from '@/api/config'
import store from '@/store'

export default {
    SendRegisterSms,
    SmsRegister,
    SendLoginSms,
    SmsLogin,
    AccountLogin,
    GetService,
    PostService,
	GetList,
    GetResourceList,
    Create,
    GetModel,
    GetResourceModel,
    Save,
    Submit,
    Reject,
    Revoke,
    Audit,
    UnAudit,
	Remove,
    Disable,
    PostMethod,
    SyncPostService,

    GetUrl
}

/**
 * 发送注册验证码
 * @param clientID
 * @param param
 * @param callback
 * @returns {Q.Promise<T>}
 * @constructor
 */
function SendRegisterSms(clientID, param, callback) {
    util.ShowProgress()

    return request({
        url: config.api.serviceUrl + clientID + '/SendRegisterSms/Authorize',
        method: 'post',
        data: param,
        headers: { auth: false }
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideProgress()
        })
}

/**
 * 验证码注册用户
 * @param clientID
 * @param param
 * @param callback
 * @returns {Q.Promise<T>}
 * @constructor
 */
function SmsRegister(clientID, param, callback) {
    util.ShowLoading()

    return request({
        url: config.api.serviceUrl + clientID + '/SmsRegister/Authorize',
        method: 'post',
        data: param,
        headers: { auth: false }
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}

/**
 * 发送登录验证码
 * @param clientID
 * @param param
 * @param callback
 * @returns {Q.Promise<T>}
 * @constructor
 */
function SendLoginSms(clientID, param, callback) {
    util.ShowProgress()

    return request({
        url: config.api.serviceUrl + clientID + '/SendLoginSms/Authorize',
        method: 'post',
        data: param,
        headers: { auth: false }
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideProgress()
        })
}


/**
 * 验证码登录
 * @param clientID
 * @param param
 * @param callback
 * @returns {Q.Promise<T>}
 * @constructor
 */
function SmsLogin(clientID, param, callback) {
    util.ShowLoading()

    return request({
        url: config.api.serviceUrl + clientID + '/SmsLogin/Authorize',
        method: 'post',
        data: param,
        headers: { auth: false }
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}

/**
 *  账号登录
 * @param clientID
 * @param param
 * @param callback
 * @returns {Q.Promise<T>}
 * @constructor
 */
function AccountLogin(clientID, param, callback) {
    util.ShowLoading()

    return request({
        url: config.api.serviceUrl + clientID + '/AccountLogin/Authorize',
        method: 'post',
        data: param,
        headers: { auth: false }
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}


function GetService(service, param, callback) {
    util.ShowLoading()

    let Api = config.api.serviceUrl + service

    if(!util.IsNull(param)){
        let query = util.ObjToForm(param)
        Api += "?" + query
    }

    return request({
        url: Api,
        method: 'get'
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideProgress()
        })
}

/**
 * 自定义接口
 * @param service
 * @param param
 * @param callback
 * @param auth
 * @returns {Q.Promise<T>}
 * @constructor
 */
function PostService(service, param, callback, auth=true) {
    util.ShowProgress()

    return request({
        url: config.api.serviceUrl + service,
        method: 'post',
        data: param,
        headers: { auth: util.IsNull(auth) ? true : auth }
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                util.HideProgress()
                callback(resp.data)
            }
        })
        .catch((error) => {
            util.HideProgress()
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
			util.HideProgress()
        })
}


function GetList(entity, param, callback, auth=true) {
    util.ShowProgress()

    return request({
        url: config.api.entityUrl + entity + "/GetList",
        method: 'post',
        data: param,
        headers: { auth: util.IsNull(auth) ? true : auth }
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                util.HideProgress()
                callback(resp.data)
            }
        })
        .catch((error) => {
            util.HideProgress()
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
			util.HideProgress()
        })
}

function GetResourceList(entity, param, callback) {
    util.ShowProgress()

    return request({
        url: config.api.entityUrl + entity + "/GetResourceList",
        method: 'post',
        data: param,
        headers: { auth: false }
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                util.HideProgress()
                callback(resp.data)
            }
        })
        .catch((error) => {
            util.HideProgress()
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
			util.HideProgress()
        })
}

/**
 * 新增
 * @param entity 实体类名
 * @param callback
 * @returns {Q.Promise<T>}
 * @constructor
 */
function Create(entity, callback) {
    util.ShowProgress()

    return request({
        url: config.api.entityUrl + entity + "/Create",
        method: 'get'
    })
    .then((resp) => {
        if (resp.data.Status === 0) {
            throw resp.data.Message
        } else {
            util.HideProgress()
            callback(resp.data)
        }
    })
    .catch((error) => {
        util.HideProgress()
        if(error !== 'UnAuthorized') util.MsgBox(error, -1)
    })
}

/**
 * 获取详情
 * @param entity
 * @param mid
 * @param callback
 * @returns {Promise<postcss.Result> | Q.Promise<any>}
 * @constructor
 */
function GetModel(entity, mid, callback) {
    util.ShowProgress()

    return request({
        url: config.api.entityUrl + entity + "/GetModel?mid=" + mid,
        method: 'get'
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                util.HideProgress()
                callback(resp.data)
            }
        })
        .catch((error) => {
            util.HideProgress()
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })

}

function GetResourceModel(entity, mid, callback) {
    util.ShowProgress()

    return request({
        url: config.api.entityUrl + entity + "/GetResourceModel?mid=" + mid,
        method: 'get',
        headers: { auth: false }
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                util.HideProgress()
                callback(resp.data)
            }
        })
        .catch((error) => {
            util.HideProgress()
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })

}

function Remove(entity, mid, callback) {
    util.ShowLoading()

    return request({
        url: config.api.entityUrl + entity + "/Remove?mid=" + mid,
        method: 'post'
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                util.HideLoading()
				util.MsgBox('删除成功！', 1)
                callback(resp.data)
            }
        })
        .catch((error) => {
            util.HideLoading()
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {

        })
}

function Disable(entity, mid, callback) {
    util.ShowLoading()

    return request({
        url: config.api.entityUrl + entity + "/Disable?mid=" + mid,
        method: 'post'
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                util.HideLoading()
				util.MsgBox('禁用成功！', 1)
                callback(resp.data)
            }
        })
        .catch((error) => {
            util.HideLoading()
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {

        })
}

function Save(entity, param, callback) {
    util.ShowLoading()

    return request({
        url: config.api.entityUrl + entity + "/Save",
        method: 'post',
        data: param
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}

function Submit(entity, mid, callback) {
    util.ShowLoading()

    return request({
        url: config.api.entityUrl + entity + "/Submit?mid=" + mid,
        method: 'post',
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}

function Reject(entity, mid, callback) {
    util.ShowLoading()

    return request({
        url: config.api.entityUrl + entity + "/Reject?mid=" + mid,
        method: 'post',
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}

function Revoke(entity, mid, callback) {
    util.ShowLoading()

    return request({
        url: config.api.serviceUrl + entity + "/Revoke?mid=" + mid,
        method: 'post',
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}

function Audit(entity, mid, callback) {
    util.ShowLoading()

    return request({
        url: config.api.entityUrl + entity + "/Audit?mid=" + mid,
        method: 'post',
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}

function UnAudit(entity, mid, callback) {
    util.ShowLoading()

    return request({
        url: config.api.entityUrl + entity + "/UnAudit?mid=" + mid,
        method: 'post',
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}

/**
 *
 * @param method
 * @param param
 * @param callback
 * @returns {*}
 * @constructor
 */
function PostMethod(method, param, callback) {
    util.ShowLoading()

    return request({
        url: config.api.webUrl + method,
        method: 'post',
        data: param
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                callback(resp.data)
            }
        })
        .catch((error) => {
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
        .finally(() => {
            util.HideLoading()
        })
}

/**
 * 同步自定义接口
 * @param service
 * @param param
 * @param auth
 * @returns {Q.Promise<T>}
 * @constructor
 */
function SyncPostService(service, param, auth=true) {
    util.ShowProgress()

    return new Promise( resolve => {
        return request({
            url: config.api.serviceUrl + service,
            method: 'post',
            data: param,
            headers: { auth: util.IsNull(auth) ? true : auth }
        })
            .then((resp) => {
                if (resp.data.Status === 0) {
                    throw resp.data.Message
                } else {
                    util.HideProgress()
                    resolve(resp.data)
                }
            })
            .catch((error) => {
                util.HideProgress()
                if(error !== 'UnAuthorized') util.MsgBox(error, -1)
            })
    })
}

function GetUrl(url, callback) {
    util.ShowProgress()

    return request({
        url: url,
        method: 'get'
    })
        .then((resp) => {
            if (resp.data.Status === 0) {
                throw resp.data.Message
            } else {
                util.HideProgress()
                callback(resp.data)
            }
        })
        .catch((error) => {
            util.HideProgress()
            if(error !== 'UnAuthorized') util.MsgBox(error, -1)
        })
}
