export default {
	menu: 'local', // local or remote
	topMenu: [{
			ID: 1,
			ParentID: 0,
			Name: 'home',
			Path: '/main/home',
			Component: '',
			Icon: 'el-icon-s-home',
			Title: '主页',
			Type: 'Group', //Group 菜单组 View 明细菜单 Page 独立页面 Url 网址链接
			Level: 0,
			Index: 1,
			Hidden: true
		}
	],
	// 管理员
	adminMenu: [
		// {
		// 	ID: 2,
		// 	ParentID: 0,
		// 	Name: 'home',
		// 	Path: '/main/home',
		// 	Component: 'views/main/home',
		// 	Icon: 'el-icon-s-home',
		// 	Title: '数据统计',
		// 	Type: 'View', //Group 菜单组 View 明细菜单 Page 独立页面 Url 网址链接
		// 	Level: 1,
		// 	Index: 1,
		// 	Hidden: false
		// },
		{
			ID: 2,
			ParentID: 0,
			Name: 'home',
			Path: '/main/home',
			Component: 'views/bill/request-list',
			Title: '调解申请单',
			Icon: 'el-icon-s-home',
			Type: 'View',
			Level: 1,
			Index: 1,
			Hidden: false
		},
		{
			ID: 3,
			ParentID: 0,
			Name: 'basic',
			Path: '/basic',
			Component: 'views/basic',
			Icon: 'el-icon-s-home',
			Title: '基础资料',
			Type: 'Group',
			Level: 1,
			Index: 2,
			Hidden: false,
			Children: [
				{
					ID: 20,
					ParentID: 3,
					Name: 'enterpriseType-list',
					Path: '/enterpriseType-list',
					Component: 'views/basic/enterpriseType-list',
					Title: '企业类型',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 21,
					ParentID: 3,
					Name: 'enterprisePosition-list',
					Path: '/enterprisePosition-list',
					Component: 'views/basic/enterprisePosition-list',
					Title: '企业职务',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: false
				},
				{
					ID: 22,
					ParentID: 3,
					Name: 'communityType-list',
					Path: '/communityType-list',
					Component: 'views/basic/communityType-list',
					Title: '组织类型',
					Type: 'View',
					Level: 2,
					Index: 3,
					Hidden: false
				},
				{
					ID: 23,
					ParentID: 3,
					Name: 'mediatorType-list',
					Path: '/mediatorType-list',
					Component: 'views/basic/mediatorType-list',
					Title: '机构类型',
					Type: 'View',
					Level: 2,
					Index: 4,
					Hidden: false
				}
			]
		},
		{
			ID: 4,
			ParentID: 0,
			Name: 'enterprise',
			Path: '/enterprise',
			Component: 'views/enterprise',
			Icon: 'el-icon-s-home',
			Title: '企业管理',
			Type: 'Group',
			Level: 1,
			Index: 3,
			Hidden: false,
			Children: [
				{
					ID: 24,
					ParentID: 4,
					Name: 'enterprise-list',
					Path: '/enterprise-list',
					Component: 'views/enterprise/enterprise-list',
					Title: '企业档案',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 25,
					ParentID: 4,
					Name: 'enterprise-detail',
					Path: '/enterprise-detail',
					Component: 'views/enterprise/enterprise-detail',
					Title: '企业档案-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
				{
					ID: 26,
					ParentID: 4,
					Name: 'enterpriseUser-list',
					Path: '/enterpriseUser-list',
					Component: 'views/enterprise/enterpriseUser-list',
					Title: '企业用户',
					Type: 'View',
					Level: 2,
					Index: 3,
					Hidden: false
				}
			]
		},
		{
			ID: 5,
			ParentID: 0,
			Name: 'community',
			Path: '/community',
			Component: 'views/community',
			Icon: 'el-icon-s-home',
			Title: '组织管理',
			Type: 'Group',
			Level: 1,
			Index: 4,
			Hidden: false,
			Children: [
				{
					ID: 27,
					ParentID: 5,
					Name: 'community-list',
					Path: '/community-list',
					Component: 'views/community/community-list',
					Title: '组织档案',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 28,
					ParentID: 5,
					Name: 'community-detail',
					Path: '/community-detail',
					Component: 'views/community/community-detail',
					Title: '组织档案-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
				{
					ID: 29,
					ParentID: 5,
					Name: 'communityUser-list',
					Path: '/communityUser-list',
					Component: 'views/community/communityUser-list',
					Title: '组织用户',
					Type: 'View',
					Level: 2,
					Index: 3,
					Hidden: false
				}
			]
		},
		{
			ID: 6,
			ParentID: 0,
			Name: 'mediator',
			Path: '/mediator',
			Component: 'views/mediator',
			Icon: 'el-icon-s-home',
			Title: '机构管理',
			Type: 'Group',
			Level: 1,
			Index: 5,
			Hidden: false,
			Children: [
				{
					ID: 30,
					ParentID: 6,
					Name: 'mediator-list',
					Path: '/mediator-list',
					Component: 'views/mediator/mediator-list',
					Title: '机构档案',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 31,
					ParentID: 6,
					Name: 'mediator-detail',
					Path: '/mediator-detail',
					Component: 'views/mediator/mediator-detail',
					Title: '机构档案-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
				{
					ID: 32,
					ParentID: 6,
					Name: 'mediatorUser-list',
					Path: '/mediatorUser-list',
					Component: 'views/mediator/mediatorUser-list',
					Title: '机构用户',
					Type: 'View',
					Level: 2,
					Index: 3,
					Hidden: false
				}
			]
		},
		{
			ID: 7,
			ParentID: 0,
			Name: 'bill1',
			Path: '/bill1',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用存证',
			Type: 'Group',
			Level: 1,
			Index: 6,
			Hidden: false,
			Children: [
				{
					ID: 33,
					ParentID: 7,
					Name: 'evidence-list',
					Path: '/evidence-list',
					Component: 'views/bill/evidence-list',
					Title: '存证申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 34,
					ParentID: 7,
					Name: 'evidence-detail',
					Path: '/evidence-detail',
					Component: 'views/bill/evidence-detail',
					Title: '存证申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
			]
		},
		{
			ID: 8,
			ParentID: 0,
			Name: 'bill2',
			Path: '/bill2',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用调解',
			Type: 'Group',
			Level: 1,
			Index: 7,
			Hidden: false,
			Children: [
				{
					ID: 35,
					ParentID: 8,
					Name: 'request-list',
					Path: '/request-list',
					Component: 'views/bill/request-list',
					Title: '调解申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 36,
					ParentID: 8,
					Name: 'request-detail',
					Path: '/request-detail',
					Component: 'views/bill/request-detail',
					Title: '调解申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
				{
					ID: 37,
					ParentID: 8,
					Name: 'response-list',
					Path: '/response-list',
					Component: 'views/bill/response-list',
					Title: '调解受理单',
					Type: 'View',
					Level: 2,
					Index: 3,
					Hidden: false
				},
				{
					ID: 38,
					ParentID: 8,
					Name: 'response-detail',
					Path: '/response-detail',
					Component: 'views/bill/response-detail',
					Title: '调解受理单-详情',
					Type: 'View',
					Level: 2,
					Index: 4,
					Hidden: true
				},
			]
		},
		{
			ID: 9,
			ParentID: 0,
			Name: 'bill3',
			Path: '/bill3',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用司法',
			Type: 'Group',
			Level: 1,
			Index: 8,
			Hidden: false,
			Children: [
				{
					ID: 39,
					ParentID: 9,
					Name: 'litigation-list',
					Path: '/litigation-list',
					Component: 'views/bill/litigation-list',
					Title: '司法申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 40,
					ParentID: 9,
					Name: 'litigation-detail',
					Path: '/litigation-detail',
					Component: 'views/bill/litigation-detail',
					Title: '司法申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
			]
		},
		{
			ID: 10,
			ParentID: 0,
			Name: 'bill4',
			Path: '/bill4',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用金融',
			Type: 'Group',
			Level: 1,
			Index: 9,
			Hidden: false,
			Children: [
				{
					ID: 41,
					ParentID: 10,
					Name: 'loanRequest-list',
					Path: '/loanRequest-list',
					Component: 'views/bill/loanRequest-list',
					Title: '贷款申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 42,
					ParentID: 10,
					Name: 'loanRequest-detail',
					Path: '/loanRequest-detail',
					Component: 'views/bill/loanRequest-detail',
					Title: '贷款申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
			]
		},
		{
			ID: 11,
			ParentID: 0,
			Name: 'system',
			Path: '/system',
			Component: 'views/system',
			Icon: 'el-icon-s-home',
			Title: '系统管理',
			Type: 'Group',
			Level: 1,
			Index: 10,
			Hidden: false,
			Children: [
				{
					ID: 49,
					ParentID: 11,
					Name: 'articleGroup-list',
					Path: '/articleGroup-list',
					Component: 'views/system/articleGroup-list',
					Title: '文章类别',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 43,
					ParentID: 11,
					Name: 'article-list',
					Path: '/article-list',
					Component: 'views/system/article-list',
					Title: '文章',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: false
				},
				{
					ID: 44,
					ParentID: 11,
					Name: 'article-detail',
					Path: '/article-detail',
					Component: 'views/system/article-detail',
					Title: '文章-详情',
					Type: 'View',
					Level: 2,
					Index: 3,
					Hidden: true
				},
				{
					ID: 45,
					ParentID: 11,
					Name: 'bannerGroup-list',
					Path: '/bannerGroup-list',
					Component: 'views/system/bannerGroup-list',
					Title: '轮播图类型',
					Type: 'View',
					Level: 2,
					Index: 4,
					Hidden: false
				},
				{
					ID: 46,
					ParentID: 11,
					Name: 'banner-list',
					Path: '/banner-list',
					Component: 'views/system/banner-list',
					Title: '轮播图',
					Type: 'View',
					Level: 2,
					Index: 5,
					Hidden: false
				},
				{
					ID: 47,
					ParentID: 11,
					Name: 'banner-detail',
					Path: '/banner-detail',
					Component: 'views/system/banner-detail',
					Title: '轮播图-详情',
					Type: 'View',
					Level: 2,
					Index: 6,
					Hidden: true
				},
				{
					ID: 48,
					ParentID: 11,
					Name: 'document-list',
					Path: '/document-list',
					Component: 'views/system/document-list',
					Title: '文件列表',
					Type: 'View',
					Level: 2,
					Index: 7,
					Hidden: false
				}
			]
		}
	],

	// 企业机构
	enterpriseMenu: [
		// {
		// 	ID: 2,
		// 	ParentID: 0,
		// 	Name: 'home',
		// 	Path: '/main/home',
		// 	Component: 'views/main/home',
		// 	Icon: 'el-icon-s-home',
		// 	Title: '数据统计',
		// 	Type: 'View',
		// 	Level: 1,
		// 	Index: 1,
		// 	Hidden: false
		// },
		{
			ID: 4,
			ParentID: 0,
			Name: 'enterprise-detail',
			Path: '/enterprise-detail',
			Component: 'views/enterprise/enterprise-detail',
			Icon: 'el-icon-s-home',
			Title: '企业档案',
			Type: 'View',
			Level: 1,
			Index: 2,
			Hidden: false
		},
		{
			ID: 7,
			ParentID: 0,
			Name: 'bill1',
			Path: '/bill1',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用存证',
			Type: 'Group',
			Level: 1,
			Index: 3,
			Hidden: false,
			Children: [
				{
					ID: 33,
					ParentID: 7,
					Name: 'evidence-list',
					Path: '/evidence-list',
					Component: 'views/bill/evidence-list',
					Title: '存证申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 34,
					ParentID: 7,
					Name: 'evidence-detail',
					Path: '/evidence-detail',
					Component: 'views/bill/evidence-detail',
					Title: '存证申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
			]
		},
		{
			ID: 8,
			ParentID: 0,
			Name: 'bill2',
			Path: '/bill2',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用调解',
			Type: 'Group',
			Level: 1,
			Index: 4,
			Hidden: false,
			Children: [
				{
					ID: 35,
					ParentID: 8,
					Name: 'request-list',
					Path: '/request-list',
					Component: 'views/bill/request-list',
					Title: '调解申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 36,
					ParentID: 8,
					Name: 'request-detail',
					Path: '/request-detail',
					Component: 'views/bill/request-detail',
					Title: '调解申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
				{
					ID: 37,
					ParentID: 8,
					Name: 'response-list',
					Path: '/response-list',
					Component: 'views/bill/response-list',
					Title: '调解受理单',
					Type: 'View',
					Level: 2,
					Index: 3,
					Hidden: false
				},
				{
					ID: 38,
					ParentID: 8,
					Name: 'response-detail',
					Path: '/response-detail',
					Component: 'views/bill/response-detail',
					Title: '调解受理单-详情',
					Type: 'View',
					Level: 2,
					Index: 4,
					Hidden: true
				}
			]
		},
		{
			ID: 9,
			ParentID: 0,
			Name: 'bill3',
			Path: '/bill3',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用司法',
			Type: 'Group',
			Level: 1,
			Index: 5,
			Hidden: false,
			Children: [
				{
					ID: 39,
					ParentID: 9,
					Name: 'litigation-list',
					Path: '/litigation-list',
					Component: 'views/bill/litigation-list',
					Title: '司法申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 40,
					ParentID: 9,
					Name: 'litigation-detail',
					Path: '/litigation-detail',
					Component: 'views/bill/litigation-detail',
					Title: '司法申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				}
			]
		},
		{
			ID: 10,
			ParentID: 0,
			Name: 'bill4',
			Path: '/bill4',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用金融',
			Type: 'Group',
			Level: 1,
			Index: 6,
			Hidden: false,
			Disabled: false,
			Entity: null,
			Children: [
				{
					ID: 41,
					ParentID: 10,
					Name: 'loanRequest-list',
					Path: '/loanRequest-list',
					Component: 'views/bill/loanRequest-list',
					Title: '贷款申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 42,
					ParentID: 10,
					Name: 'loanRequest-detail',
					Path: '/loanRequest-detail',
					Component: 'views/bill/loanRequest-detail',
					Title: '贷款申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				}
			]
		}
	],

	// 调解机构
	mediatorMenu: [
		// {
		// 	ID: 2,
		// 	ParentID: 0,
		// 	Name: 'home',
		// 	Path: '/main/home',
		// 	Component: 'views/main/home',
		// 	Icon: 'el-icon-s-home',
		// 	Title: '我的主页',
		// 	Type: 'View',
		// 	Level: 1,
		// 	Index: 1,
		// 	Hidden: false
		// },
		{
			ID: 3,
			ParentID: 0,
			Name: 'mediator-detail',
			Path: '/mediator-detail',
			Component: 'views/mediator/mediator-detail',
			Icon: 'el-icon-s-home',
			Title: '调解机构',
			Type: 'View',
			Level: 1,
			Index: 2,
			Hidden: false
		},
		{
			ID: 4,
			ParentID: 0,
			Name: 'bill2',
			Path: '/bill2',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用调解',
			Type: 'Group',
			Level: 1,
			Index: 3,
			Hidden: false,
			Children: [
				{
					ID: 34,
					ParentID: 7,
					Name: 'evidence-detail',
					Path: '/evidence-detail',
					Component: 'views/bill/evidence-detail',
					Title: '存证申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
				{
					ID: 35,
					ParentID: 4,
					Name: 'request-list',
					Path: '/request-list',
					Component: 'views/bill/request-list',
					Title: '调解申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 36,
					ParentID: 4,
					Name: 'request-detail',
					Path: '/request-detail',
					Component: 'views/bill/request-detail',
					Title: '调解申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
				{
					ID: 37,
					ParentID: 4,
					Name: 'response-list',
					Path: '/response-list',
					Component: 'views/bill/response-list',
					Title: '调解受理单',
					Type: 'View',
					Level: 2,
					Index: 3,
					Hidden: false
				},
				{
					ID: 38,
					ParentID: 4,
					Name: 'response-detail',
					Path: '/response-detail',
					Component: 'views/bill/response-detail',
					Title: '调解受理单-详情',
					Type: 'View',
					Level: 2,
					Index: 4,
					Hidden: true
				},
			]
		}
	],

	// 社会组织
	communityMenu: [
		// {
		// 	ID: 2,
		// 	ParentID: 0,
		// 	Name: 'home',
		// 	Path: '/main/home',
		// 	Component: 'views/main/home',
		// 	Icon: 'el-icon-s-home',
		// 	Title: '我的主页',
		// 	Type: 'View',
		// 	Level: 1,
		// 	Index: 1,
		// 	Hidden: false
		// },
		{
			ID: 3,
			ParentID: 0,
			Name: 'community-detail',
			Path: '/community-detail',
			Component: 'views/community/community-detail',
			Icon: 'el-icon-s-home',
			Title: '组织档案',
			Type: 'View',
			Level: 1,
			Index: 2,
			Hidden: false
		},
		{
			ID: 4,
			ParentID: 0,
			Name: 'enterprise',
			Path: '/enterprise',
			Component: 'views/enterprise',
			Icon: 'el-icon-s-home',
			Title: '企业管理',
			Type: 'Group',
			Level: 1,
			Index: 3,
			Hidden: false,
			Children: [
				{
					ID: 24,
					ParentID: 4,
					Name: 'enterprise-list',
					Path: '/enterprise-list',
					Component: 'views/enterprise/enterprise-list',
					Title: '企业档案',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 25,
					ParentID: 4,
					Name: 'enterprise-detail',
					Path: '/enterprise-detail',
					Component: 'views/enterprise/enterprise-detail',
					Title: '企业档案-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				}
			]
		},
		{
			ID: 8,
			ParentID: 0,
			Name: 'bill2',
			Path: '/bill2',
			Component: 'views/bill',
			Icon: 'el-icon-s-home',
			Title: '信用调解',
			Type: 'Group',
			Level: 1,
			Index: 4,
			Hidden: false,
			Disabled: false,
			Entity: null,
			Children: [
				{
					ID: 35,
					ParentID: 8,
					Name: 'request-list',
					Path: '/request-list',
					Component: 'views/bill/request-list',
					Title: '调解申请单',
					Type: 'View',
					Level: 2,
					Index: 1,
					Hidden: false
				},
				{
					ID: 36,
					ParentID: 8,
					Name: 'request-detail',
					Path: '/request-detail',
					Component: 'views/bill/request-detail',
					Title: '调解申请单-详情',
					Type: 'View',
					Level: 2,
					Index: 2,
					Hidden: true
				},
				{
					ID: 37,
					ParentID: 8,
					Name: 'response-list',
					Path: '/response-list',
					Component: 'views/bill/response-list',
					Title: '调解受理单',
					Type: 'View',
					Level: 2,
					Index: 3,
					Hidden: false
				},
				{
					ID: 38,
					ParentID: 8,
					Name: 'response-detail',
					Path: '/response-detail',
					Component: 'views/bill/response-detail',
					Title: '调解受理单-详情',
					Type: 'View',
					Level: 2,
					Index: 4,
					Hidden: true
				}
			]
		}
	]
}
