import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import util from '@/utils/common'

router.beforeEach(async function(to, from, next) {
	// console.log('TO', to)
	// console.log('FROM', from)
    // if (store.getters.Token === '' && to.fullPath !== '/login') {
    //     util.MsgBox('会话已过期，请重新登陆！')
    //     util.NavigateTo('/login')
    //     return
    // }
    const value = to.fullPath
    const meta = to.meta
    const label = meta.title
    if (meta.isTab !== false && !util.IsNull(value) && !util.IsNull(label)) {
        store.commit('ADD_TAG', {
            label: label,
            value: value,
            params: to.params,
            query: to.query,
            meta: (() => {
                return meta
            })()
        })
    }
    next()
})

// 缓存
Vue.mixin({
    beforeRouteLeave: function(to, from, next) {
        if (this.$route.meta.keepAlive === true) {
            const result =
                this.$route.meta.keepAlive === true &&
                store.state.tags.tagList.some((ele) => {
                    return ele.value === this.$route.fullPath
                })
            if (this.$vnode && !result) {
                if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache) {
                    if (this.$vnode.componentOptions) {
                        var key = this.$vnode.key == null ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '') : this.$vnode.key
                        var cache = this.$vnode.parent.componentInstance.cache
                        var keys = this.$vnode.parent.componentInstance.keys
                        if (cache[key]) {
                            if (keys.length) {
                                var index = keys.indexOf(key)
                                if (index > -1) {
                                    keys.splice(index, 1)
                                }
                            }
                            delete cache[key]
                        }
                    }
                }
            }
        }
        next()
    }
})
