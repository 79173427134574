import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/router/router-event'

// Avue
Vue.use(window.AVUE)

// Element-UI
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element, {
    size: 'large' // set element-ui default size
})

// 全局CSS
import './styles/common.scss'

// 全局组件
import appContainer from './components/app-container/appContainer'
Vue.component('appContainer', appContainer)

// 全局变量/函数
import config from '@/api/config'
import util from '@/utils/common.js'
import interfaceAPI from '@/api/interface.js'
import menu from '@/api/menu.js'

Vue.prototype.$Config = config
Vue.prototype.$Util = util
Vue.prototype.$API = interfaceAPI
Vue.prototype.$Menu = menu

Vue.config.productionTip = false

// 动态加载阿里云字体库
import theme from '@/utils/theme.js'
let iconfontVersion = ['567566_pwc3oottzol']
let iconfontUrl = `//at.alicdn.com/t/font_$key.css`
iconfontVersion.forEach((ele) => {
    theme.LoadFontStyle(iconfontUrl.replace('$key', ele))
})

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
